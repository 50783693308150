import React from 'react';
import MaterialTable from 'material-table';
import GlimraStore from '../store/GlimraStore';
import moment from 'moment';
import LoggedInRoot from '../components/helpers/LoggedInRoot';
import PageHeader from '../components/PageHeader';
import theme from '../styles/theme';
import history from '../store/HistoryStore';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../navigation/routes';
import LoggedInContent from '../components/helpers/LoggedInContent';

const HistoryPage = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('historyPage.columnDate'),
      field: 'created',
      render: (row) => moment(row.created).format('YY-MM-DD'),
    },
    {
      title: t('historyPage.columnProduct'),
      sorting: false,
      render: ({ subscription }) =>
        subscription
          ? t('historyPage.columnProductSubscription')
          : t('historyPage.columnProductDiyWash'),
    },
    {
      title: t('historyPage.columnInformation'),
      sorting: false,
      render: ({ subscription, site_name, details_verbose }) =>
        subscription ? details_verbose.vehicle?.number : site_name,
    },
    {
      title: t('historyPage.columnAmount'),
      field: 'amount',
    },
  ];

  const fetchPaymentsData = ({ page, pageSize, orderBy, orderDirection }) => {
    const orderField = orderBy ? orderBy.field : '-created';
    const direction = orderDirection === 'desc' ? '-' : '';
    const order = direction + orderField;

    return GlimraStore.listPayments(page + 1, pageSize, order).then(({ data }) => {
      return { data: data.results, page: page, totalCount: data.count };
    });
  };

  const navigateToUserHistory = (_, { uuid }) => {
    history.push(ROUTES.USER_HISTORY.replace(':uuid', uuid));
  };

  return (
    <LoggedInRoot>
      <PageHeader header={t('historyPage.header')} subtitle={t('historyPage.subtitle')} />
      <LoggedInContent>
        <MaterialTable
          title={t('historyPage.historyTableTitle')}
          components={{
            Toolbar: () => null,
            Container: ({ children }) => <div>{children}</div>,
          }}
          columns={columns}
          data={(query) => fetchPaymentsData(query)}
          onRowClick={navigateToUserHistory}
          options={{
            debounceInterval: 350,
            pageSize: 10,
            search: false,
            headerStyle: {
              backgroundColor: theme.palette.grey.glimraLight,
              fontWeight: theme.typography.fontWeightBold,
            },
          }}
        />
      </LoggedInContent>
    </LoggedInRoot>
  );
};

export default HistoryPage;
