import React from 'react';
import { DialogActions, FormControlLabel, Grid, Checkbox, FormHelperText } from '@material-ui/core';
import Button from '../helpers/Button';
import { useTranslation } from 'react-i18next';
import WhiteTextField from '../helpers/WhiteTextField';

const TermsLabel = ({ text, linkText, link }) => (
  <>
    {text}
    <a href={link} target="_bland">
      {linkText}
    </a>
  </>
);

const NewCustomerForm = ({ formik }) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column" spacing={2} component="form">
      <Grid item>
        <WhiteTextField
          required
          id="first_name"
          autoComplete="given-name"
          label={t('newCustomerForm.firstNameLabel')}
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.touched.first_name && !!formik.errors.first_name}
          helperText={formik.touched.first_name && formik.errors.first_name}
          onBlur={formik.handleBlur}
          fullWidth
        />
      </Grid>
      <Grid item>
        <WhiteTextField
          required
          id="last_name"
          autoComplete="family-name"
          label={t('newCustomerForm.lastNameLabel')}
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.touched.last_name && !!formik.errors.last_name}
          helperText={formik.touched.last_name && formik.errors.last_name}
          onBlur={formik.handleBlur}
          fullWidth
        />
      </Grid>
      <Grid item>
        <WhiteTextField
          required
          id="email"
          type="email"
          autoComplete="email"
          label={t('newCustomerForm.emailLabel')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          onBlur={formik.handleBlur}
          fullWidth
        />
      </Grid>
      <Grid item>
        <WhiteTextField
          id="mobile"
          label={t('newCustomerForm.mobileLabel')}
          type="tel"
          autoComplete="tel"
          required
          value={formik.values.mobile}
          error={formik.touched.mobile && !!formik.errors.mobile}
          helperText={formik.touched.mobile && formik.errors.mobile}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          fullWidth
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          label={t('newCustomerForm.marketingLabel')}
          control={
            <Checkbox
              id="marketing_ok"
              color="primary"
              onChange={formik.handleChange}
              value={formik.values.marketing_ok ? 'on' : 'off'}
              checked={formik.values.marketing_ok}
            />
          }
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          label={
            <TermsLabel
              text={t('newCustomerForm.acceptTerms')}
              linkText={t('newCustomerForm.acceptTermsLinkText')}
              link={t('newCustomerForm.termsLink')}
            />
          }
          control={
            <Checkbox
              id="accepts_terms"
              color="primary"
              onChange={formik.handleChange}
              value={formik.values.accepts_terms ? 'on' : 'off'}
              checked={formik.values.accepts_terms}
            />
          }
        />
        <FormHelperText error={true}>
          {formik.submitCount ? formik.errors.accepts_terms : ''}
        </FormHelperText>
      </Grid>
      <Grid item>
        <DialogActions>
          <Button color="default" variant="contained" onClick={formik.handleSubmit}>
            {t('newCustomerForm.buttonRegister')}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default NewCustomerForm;
