import GlimraStore from './GlimraStore';
import moment from 'moment';
import history from '../store/HistoryStore';
import { ROUTES } from '../navigation/routes';

class AuthStore {
  login = (token) => {
    const data = {
      token,
    };

    return GlimraStore.noAuthInstance
      .post('/account/callback/auth/', data)
      .then(({ data }) => {
        localStorage.setItem('token', data.token);
        localStorage.setItem('tokenExpiration', data.expiration);
        localStorage.setItem('refreshToken', data.refresh_token);
        GlimraStore.instance.defaults.headers.common['Authorization'] = `Token ${data.token}`;

        return data;
      })
      .catch((err) => {
        throw err;
      });
  };

  refreshToken = () => {
    // if auth token is expired
    if (moment(localStorage.getItem('tokenExpiration')).isBefore()) {
      const data = {
        refresh_token: localStorage.getItem('refreshToken'),
      };

      return GlimraStore.noAuthInstance
        .post('/account/refresh/auth/', data)
        .then(({ data }) => {
          localStorage.setItem('token', data.token);
          localStorage.setItem('tokenExpiration', data.expiration);
          localStorage.setItem('refreshToken', data.refresh_token);
          GlimraStore.instance.defaults.headers.common['Authorization'] = `Token ${data.token}`;

          return data;
        })
        .catch((err) => {
          history.push(ROUTES.LOGOUT);
        });
    }
  };

  logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('refreshToken');
  };

  getAuthStatus = () => {
    return !!localStorage.getItem('token');
  };

  getCookieConsentStatus = () => {
    return !!localStorage.getItem('cookieConsent');
  };

  acceptCookies = () => {
    localStorage.setItem('cookieConsent', true);
  };
}

const singleton = new AuthStore();
export default singleton;
