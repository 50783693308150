import React from 'react';
import { withStyles, Container, Grid, Typography, Box, Link, IconButton } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import SpacerVertical from './helpers/SpacerVertical';
import { useTranslation } from 'react-i18next';
import IMAGES from '../styles/images';

const FooterSection = withStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    backgroundImage: `linear-gradient(180deg, ${theme.palette.footer.start} 0%, ${theme.palette.footer.end} 100%)`,
    color: theme.palette.common.white,
  },
}))(Box);

const FooterColumn = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(Grid);

const SocialMediaIconButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.footer.icon,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  },
}))(IconButton);

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterSection>
      <Container disableGutters>
        <Grid item xs={12}>
          <Grid container>
            <FooterColumn item xs={12} md={4}>
              <Grid container direction="column">
                <Grid item>
                  <a href={t('links.homepage')}>
                    <Box
                      component="img"
                      src={IMAGES.logo}
                      alt="Glimra footer logo"
                      maxHeight={50}
                      mb={2}
                    />
                  </a>
                </Grid>
                <Grid item>
                  <Typography paragraph variant="body2">
                    {t('footer.companyDescription')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography paragraph variant="body2">
                    {t('footer.email')}{' '}
                    <Link color="error" href={`mailto:${t('links.supportEmail')}`}>
                      {t('links.supportEmail')}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography paragraph variant="body2">
                    {t('footer.phoneNumber')}{' '}
                    <Link color="error" href="tel:0770829040">
                      {t('footer.companySupportNumber')}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography paragraph variant="body2">
                    {t('footer.companyInfoLine1')}
                    <br />
                    {t('footer.companyInfoLine2')}
                    <br />
                    {t('footer.companyInfoLine3')}
                  </Typography>
                </Grid>
              </Grid>
            </FooterColumn>
            <FooterColumn item xs={12} md={4}>
              <Grid container direction="column">
                <Grid item>
                  <Box fontWeight="fontWeightBold">{t('footer.mobileLinksHeader')}</Box>
                </Grid>
                <Grid item>
                  <SpacerVertical size="small" />
                </Grid>
                <Grid item>
                  <a target="_blank" rel="noopener noreferrer" href={t('links.appApple')}>
                    <Box
                      component="img"
                      src={IMAGES.badgeAppleAppStore}
                      alt="Apple store"
                      maxWidth={113}
                    />
                  </a>
                </Grid>
                <Grid item>
                  <a target="_blank" rel="noopener noreferrer" href={t('links.appApple')}>
                    <Box
                      component="img"
                      src={IMAGES.badgeGooglePlay}
                      alt="Google Play store"
                      maxWidth={113}
                    />
                  </a>
                </Grid>
              </Grid>
            </FooterColumn>
            <FooterColumn item xs={12} md={4}>
              <Grid container direction="column">
                <Grid item>
                  <Box fontWeight="fontWeightBold">{t('footer.socialMediaHeader')}</Box>
                </Grid>
                <Grid item>
                  <SpacerVertical size="small" />
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <SocialMediaIconButton href="https://www.facebook.com/glimrasverige/">
                        <FacebookIcon />
                      </SocialMediaIconButton>
                    </Grid>
                    <Grid item>
                      <SocialMediaIconButton href="https://www.instagram.com/glimrasverige/">
                        <InstagramIcon />
                      </SocialMediaIconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FooterColumn>
          </Grid>
        </Grid>
      </Container>
    </FooterSection>
  );
};

export default Footer;
