import React, { useMemo, useState } from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import history from '../store/HistoryStore';
import GlimraStore from '../store/GlimraStore';
import OneTimeCodeDialog from '../components/OneTimeCodeDialog';
import LoggedInRoot from '../components/helpers/LoggedInRoot';
import PageHeader from '../components/PageHeader';
import SpacerVertical from '../components/helpers/SpacerVertical';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../navigation/routes';
import FabButton from '../components/helpers/FabButton';
import LoggedInContent from '../components/helpers/LoggedInContent';
import { SubscriptionStatus } from '../data/subscriptionStatus';

const cellStyle = {
  padding: '1rem 0.25rem',
};

const SubscriptionsPage = () => {
  const [oneTimeCodeDialogOpen, setOneTimeCodeDialogOpen] = useState(false);
  const theme = useTheme();
  const tinyScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  const columns = useMemo(() => {
    const columns = [
      {
        title: t('subscriptionPage.columnVehicle'),
        field: 'vehicle_number',
      },
      {
        title: t('subscriptionPage.columnProgram'),
        field: 'program',
        render: ({ details }) => details?.program_product?.name,
      },
      {
        title: t('subscriptionPage.columnStatus'),
        field: 'status',
        render: ({ details }) => {
          const status = details.status;
          if (status === SubscriptionStatus.CANCELED) {
            return t('subscriptionPage.cancelled');
          }
          // Planned cancellation
          if (details.cancel_at_binding_end || !!details.cancel_at) {
            return t('subscriptionPage.plannedCancellation');
          }
          if (status === SubscriptionStatus.ACTIVE || status === SubscriptionStatus.TRIALING) {
            return t('subscriptionPage.active');
          }
          return t('subscriptionPage.inactive');
        },
      },
    ];
    /* If not tiny screen, add when it expires */
    if (!tinyScreen) {
      columns.splice(2, 0, {
        title: t('subscriptionPage.columnExpires'),
        field: 'expires',
        render: (row) => moment(row.expires).format('YY-MM-DD'),
        sorting: false,
      });
    }
    /* If not small screen, add the monthly cost */
    if (!smallScreen) {
      columns.splice(3, 0, {
        title: t('subscriptionPage.columnMonthlyCost'),
        field: 'monthly_cost',
        sorting: false,
      });
    }

    /* If not medium screen, add the availability */
    if (!mediumScreen) {
      columns.splice(2, 0, {
        title: t('subscriptionPage.columnAvailability'),
        field: 'availability',
        render: ({ details }) => details?.availability_product?.name,
      });
    }

    return columns.map((el) => ({ ...el, cellStyle }));
  }, [mediumScreen, smallScreen, tinyScreen, t]);

  const fetchSubscriptionsData = ({ page, pageSize, orderBy, orderDirection }) => {
    const orderField = orderBy ? orderBy.field : '-created';
    const direction = orderDirection === 'desc' ? '-' : '';
    const order = direction + orderField;

    return GlimraStore.listSubscriptions(page + 1, pageSize, order).then((data) => {
      return { data: data.results, page: page, totalCount: data.count };
    });
  };

  const navigateToSubscription = (_, { id }) => {
    history.push(ROUTES.SUBSCRIPTION.replace(':id', id));
  };

  return (
    <LoggedInRoot>
      <PageHeader header={t('subscriptionPage.header')} subtitle={t('subscriptionPage.subtitle')} />
      <LoggedInContent>
        <MaterialTable
          columns={columns}
          data={(query) => fetchSubscriptionsData(query)}
          options={{
            emptyRowsWhenPaging: false,
            debounceInterval: 350,
            pageSize: 10,
            search: false,
            headerStyle: {
              backgroundColor: theme.palette.grey.glimraLight,
              fontWeight: theme.typography.fontWeightBold,
              ...cellStyle,
            },
          }}
          components={{
            Toolbar: () => null,
            Container: ({ children }) => <div>{children}</div>,
          }}
          onRowClick={navigateToSubscription}
        />

        <SpacerVertical />
        <Grid container spacing={2}>
          <Grid item>
            <FabButton
              size="small"
              variant="extended"
              color="default"
              onClick={() => history.push(ROUTES.CHOOSE_SUBSCRIPTION)}
            >
              {t('subscriptionPage.buttonAddVehicle')}
            </FabButton>
          </Grid>
          <Grid item>
            <FabButton
              size="small"
              variant="extended"
              color="default"
              onClick={() => {
                setOneTimeCodeDialogOpen(true);
              }}
            >
              {t('subscriptionPage.buttonCreateOnetimeCode')}
            </FabButton>
          </Grid>
        </Grid>
        <OneTimeCodeDialog
          open={oneTimeCodeDialogOpen}
          onClose={() => setOneTimeCodeDialogOpen(false)}
        />
      </LoggedInContent>
    </LoggedInRoot>
  );
};

export default SubscriptionsPage;
