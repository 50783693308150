import React from 'react';
import PaymentMethod from '../components/PaymentMethod';
import LoggedInRoot from '../components/helpers/LoggedInRoot';
import PageHeader from '../components/PageHeader';
import { useTranslation } from 'react-i18next';
import LoggedInContent from '../components/helpers/LoggedInContent';

const PaymentMethodPage = () => {
  const { t } = useTranslation();

  return (
    <LoggedInRoot>
      <PageHeader
        header={t('paymentMethodPage.header')}
        subtitle={t('paymentMethodPage.subtitle')}
      />
      <LoggedInContent>
        <PaymentMethod />
      </LoggedInContent>
    </LoggedInRoot>
  );
};

export default PaymentMethodPage;
