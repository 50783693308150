import React, { useContext, useEffect } from 'react';
import AuthStore from '../store/AuthStore';
import { Redirect } from 'react-router-dom';
import LoginContext from '../store/LoginContext';

const LogoutPage = () => {
  const loginStatus = useContext(LoginContext);

  useEffect(() => {
    loginStatus.setLoggedIn(false);
    AuthStore.logout();
  }, [loginStatus]);

  return <Redirect to="/" />;
};

export default LogoutPage;
