const colors = {
  // Glimra
  glimraTealLight: '#6dadbd',
  glimraTealDark: '#348796',
  glimraBlueLight: '#006481',
  glimraBlueDark: '#004357',
  glimraRedLight: '#ffa09e',
  glimraRedMedium: '#ff6d6a',
  glimraRedDarker: '#e66260',
  glimraRedDarkest: '#cc5856',

  glimraGreyLight: '#f3f3f3',
  glimraGreyMedium: '#ececec',
  glimraGreyDark: '#898d8d',

  glimraFooterStart: '#164252',
  glimraFooterEnd: '#0099AB',
  glimraIconTeal: '#338796',

  white: '#ffffff',

  semiTransparent: 'rgba(255, 255, 255, 0.25)',

  juhlapesuRedLight: '#eb1000',
  juhlapesuRed: '#b70c00',
  juhlapesuRedDark: '#850900',
  juhlapesuRedDarker: '#520500',

  juhlapesuLight: '#fcf3f6',
  juhlapesuPink: '#f6dbe6',
  juhlapesuPinkDark: '#ecb1c9',
};

export default colors;
