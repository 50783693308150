const PIXEL_ID = process.env.REACT_APP_PIXEL_ID;

export const hasInitialized = () => !!window.fbq;

/* Initialize the Pixel */
export const initPixel = () => {
  if (!!PIXEL_ID) {
    // eslint-disable-next-line no-unused-expressions
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', PIXEL_ID);
  } else {
    console.warn('No Pixel ID configured, skipping setup');
  }
};

/**
 * Tracks a user purchase
 * @param {string} value - The value of the purchase
 * @param {string} currency - The currency used for purchase
 */
export const trackPayment = (value, currency) => {
  if (!hasInitialized()) {
    return;
  }

  fbq('track', 'Purchase', { value, currency });
};
