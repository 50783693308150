import React, { useState, useEffect } from 'react';

import {
  withStyles,
  Container,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Button,
} from '@material-ui/core';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import GlimraStore from '../store/GlimraStore';
import { snackRef } from '../components/Snack';
import { extractErrorMessage } from '../helpers/apiUtils';
import history from '../store/HistoryStore';
import { useTranslation } from 'react-i18next';
import IMAGES from '../styles/images';
import WhiteTextField from '../components/helpers/WhiteTextField';
import { ROUTES } from '../navigation/routes';

const styles = (theme) => ({
  header: {
    zIndex: theme.zIndex.drawer + 2,
    width: '100%',
    padding: '1rem 1rem 0.5rem',
    position: 'fixed',
    color: theme.palette.common.white,
    transition: theme.transitions.create(['background-color', 'box-shadow']),
  },

  headerLogo: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  elevateHeader: {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: theme.shadows[2],
  },

  jumbotronContainer: {
    minHeight: 'calc(100vh)',
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    backgroundImage: `url(${IMAGES.landingPageBanner})`,
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
  },

  pageHeading: {
    fontWeight: 600,
    zIndex: 10,
  },

  secondaryHeader: {
    fontWeight: 600,
    marginBottom: '1rem',
  },

  loginText: {
    marginBottom: '2rem',
  },

  registerText: {
    marginTop: '2rem',
    marginBottom: '1rem',
  },

  loginButton: {
    padding: '0 2rem',
    height: '35px',
    minWidth: '125px',
    color: theme.palette.defaultButton.contrastText,
    backgroundColor: theme.palette.defaultButton.main,
    '&:hover': {
      backgroundColor: theme.palette.defaultButton.dark,
      '@media (hover: none)': {
        backgroundColor: theme.palette.defaultButton.main,
      },
    },
  },

  loginButtonDisabled: {
    backgroundColor: `${theme.palette.defaultButton.main} !important`,
    opacity: 0.5,
    color: `${theme.palette.common.white} !important`,
  },
});

const LandingPage = ({ classes }) => {
  const [elevateHeader, setElevateHeader] = useState(false);
  const { t } = useTranslation();

  const form = useFormik({
    initialValues: {
      mobile: '',
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      mobile: Yup.string().required(t('existingCustomerForm.mobileRequired')),
    }),

    onSubmit: (values, actions) => {
      const data = {
        mobile: values.mobile,
        desktop: true,
      };

      GlimraStore.createUser(data)
        .then((responseData) => {
          history.push(ROUTES.LOGIN);
        })
        .catch((error) => {
          actions.setSubmitting(false);
          snackRef.current.updateSnack({
            variant: 'error',
            title: t('existingCustomerForm.verificationCodeError.title'),
            message: extractErrorMessage(
              error,
              t('existingCustomerForm.verificationCodeError.message')
            ),
          });
        });
    },
  });

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setElevateHeader(true);
    } else {
      setElevateHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <header className={`${classes.header} ${elevateHeader ? classes.elevateHeader : ''}`}>
        <Container maxWidth="xl">
          <Grid container alignItems="center">
            <Grid
              item
              xs={12}
              sm={4}
              component="a"
              href="https://glimra.se"
              className={classes.headerLogo}
            >
              <Box component="img" src={IMAGES.logo} alt="Logo" maxWidth={125} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="h4"
                component="h1"
                align="center"
                className={classes.pageHeading}
              >
                {t('landingPage.header')}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </header>
      <div className={classes.jumbotronContainer}>
        <Container maxWidth="sm" className={classes.contentContainer}>
          <Typography variant="h4" component="h2" align="center" className={classes.loginText}>
            {t('landingPage.loginText')}
          </Typography>
          <Grid
            container
            justify="center"
            spacing={2}
            component="form"
            onSubmit={form.handleSubmit}
          >
            <Grid item xs={12} sm={4}>
              <WhiteTextField
                fullWidth
                id="mobile"
                type="phone"
                autoComplete="tel"
                placeholder={t('existingCustomerForm.mobileLabel')}
                value={form.values.mobile}
                error={!!form.touched.mobile && !!form.errors.mobile}
                helperText={form.touched.mobile && form.errors.mobile}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant="contained"
                color="default"
                type="submit"
                disabled={form.isSubmitting || !form.isValid}
                classes={{
                  root: classes.loginButton,
                  disabled: classes.loginButtonDisabled,
                }}
                onClick={form.handleSubmit}
              >
                {form.isSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  t('landingPage.buttonLogin')
                )}
              </Button>
            </Grid>
          </Grid>
          <Typography variant="h4" component="h3" align="center" className={classes.registerText}>
            {t('landingPage.registerText')}
          </Typography>
          <Typography variant="h5" align="center" className={classes.secondaryHeader}>
            {t('landingPage.secondaryHeader')}
          </Typography>
          <Box justifyContent="center" display="flex">
            <Button
              onClick={() => history.push(ROUTES.REGISTER)}
              variant="contained"
              color="default"
              classes={{
                root: classes.loginButton,
              }}
            >
              {t('landingPage.buttonRegister')}
            </Button>
          </Box>
        </Container>
      </div>
    </Box>
  );
};

export default withStyles(styles)(LandingPage);
