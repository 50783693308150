import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import React from 'react';

import App from './App';

const dev = process.env.NODE_ENV === 'development';
const sentryEnv = process.env.REACT_APP_SENTRY_ENV || (dev ? 'dev' : 'prod');

Sentry.init({
  dsn: 'https://636f1a1407c34710b3814fda90f19956@o304985.ingest.sentry.io/5280026',
  environment: sentryEnv,
  attachStacktrace: true,
  maxBreadcrumbs: 50,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
