import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { DialogActions, Grid } from '@material-ui/core';
import GlimraStore from '../../store/GlimraStore';
import { snackRef } from '../../components/Snack';
import { extractErrorCode } from '../../helpers/apiUtils';
import * as Yup from 'yup';
import { vehicleRegistrationNumberValidation } from '../../helpers/formUtils';
import Button from '../helpers/Button';
import { useTranslation } from 'react-i18next';
import WhiteTextField from '../helpers/WhiteTextField';
import { trackPayment } from '../../store/FBPixel';
import getCurrency from '../../helpers/getCurrency';
import AlternativesContext from '../../store/AlternativesContext';
import { calculatePrice } from '../../helpers/alternativesUtils';

const INSUFFICIENT_FUNDS_CODE = 'insufficient_funds';
const NON_UNIQUE_CODE = 'unique';

const LoggedInCustomerForm = ({ programId, availabiltyId, paymentIntervalId, setStep }) => {
  const alternatives = useContext(AlternativesContext).alternatives;
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      vehicle_number: '',
      program: programId,
      availability: availabiltyId,
      binding: paymentIntervalId,
    },
    onSubmit: (values) => {
      const data = {
        chosen_program: values.program,
        chosen_availability: values.availability,
        chosen_binding_time: values.binding,
        vehicle_number: values.vehicle_number,
      };

      const cost = calculatePrice(
        alternatives,
        data.chosen_program,
        data.chosen_availability,
        data.chosen_binding_time
      );

      GlimraStore.createSubscription(data)
        .then((data) => {
          if (data.country !== undefined) {
            trackPayment(cost, getCurrency(data.country));
          }

          setStep(3);
        })
        .catch((error) => {
          const errorCode = extractErrorCode(error.response?.data);
          if (errorCode === INSUFFICIENT_FUNDS_CODE) {
            snackRef.current.updateSnack({
              variant: 'error',
              title: t('loggedInCustomerForm.createSubscriptionInsufficientFunds.title'),
              message: t('loggedInCustomerForm.createSubscriptionInsufficientFunds.message'),
            });
          } else if (errorCode === NON_UNIQUE_CODE) {
            snackRef.current.updateSnack({
              variant: 'error',
              title: t('loggedInCustomerForm.createSubscriptionUniqueError.title'),
              message: t('loggedInCustomerForm.createSubscriptionUniqueError.message'),
            });
          } else {
            snackRef.current.updateSnack({
              variant: 'error',
              title: t('loggedInCustomerForm.createSubscriptionError.title'),
              message: t('loggedInCustomerForm.createSubscriptionError.message'),
            });
          }
        });
    },
    validationSchema: Yup.object().shape({
      vehicle_number: vehicleRegistrationNumberValidation(t),
    }),
  });

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <WhiteTextField
          required
          id="vehicle_number"
          label={t('existingCustomerForm.vehicleLabel')}
          value={formik.values.vehicle_number}
          onChange={(event) => {
            formik.setFieldValue('vehicle_number', event.target.value.toUpperCase());
          }}
          error={formik.touched.vehicle_number && !!formik.errors.vehicle_number}
          helperText={formik.touched.vehicle_number && formik.errors.vehicle_number}
          onBlur={formik.handleBlur}
          fullWidth
        />
      </Grid>
      <Grid item>
        <DialogActions>
          <Button color="default" variant="contained" onClick={formik.handleSubmit}>
            {t('existingCustomerForm.buttonSend')}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default LoggedInCustomerForm;
