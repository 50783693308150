import React from 'react';
import ChooseSubscriptionPage from '../pages/ChooseSubscriptionPage';
import LoginPage from '../pages/LoginPage';
import RegistrationPage from '../pages/RegistrationPage';
import HistoryPage from '../pages/HistoryPage';
import LogoutPage from '../pages/LogoutPage';
import PaymentMethodPage from '../pages/PaymentMethodPage';
import PaymentPage from '../pages/PaymentPage';
import SettingsPage from '../pages/SettingsPage';
import SubscriptionsPage from '../pages/SubscriptionsPage';
import UpdateSubscriptionPage from '../pages/UpdateSubscriptionPage';
import {
  DirectionsCar,
  MeetingRoom,
  CreditCard,
  CardMembership,
  History,
  Settings,
} from '@material-ui/icons';

export const ROUTES = {
  LANDING: '/',
  SUBSCRIPTIONS: '/subscriptions',
  SUBSCRIPTION: '/subscriptions/:id',
  HISTORY: '/history',
  USER_HISTORY: '/history/:uuid',
  CHOOSE_SUBSCRIPTION: '/chooseSubscription',
  REGISTER: '/register',
  PAYMENT_METHOD: '/paymentMethod',
  SETTINGS: '/settings',
  LOGIN: '/login',
  LOGOUT: '/logout',
};

const subPages = [
  {
    path: ROUTES.SUBSCRIPTION,
    component: UpdateSubscriptionPage,
  },
  {
    path: ROUTES.USER_HISTORY,
    component: PaymentPage,
  },
];

export const pageRoutes = [
  {
    path: ROUTES.CHOOSE_SUBSCRIPTION,
    title: 'routes.chooseSubscription',
    icon: <DirectionsCar />,
    component: ChooseSubscriptionPage,
  },
  {
    path: ROUTES.SUBSCRIPTIONS,
    title: 'routes.subscriptions',
    icon: <CardMembership />,
    component: SubscriptionsPage,
  },
  {
    path: ROUTES.HISTORY,
    title: 'routes.history',
    icon: <History />,
    component: HistoryPage,
  },
  {
    path: ROUTES.PAYMENT_METHOD,
    title: 'routes.paymentMethod',
    icon: <CreditCard />,
    component: PaymentMethodPage,
  },
  {
    path: ROUTES.SETTINGS,
    title: 'routes.settings',
    icon: <Settings />,
    component: SettingsPage,
  },
];

export const authRoutes = [
  {
    path: ROUTES.LOGOUT,
    title: 'routes.logout',
    icon: <MeetingRoom />,
    component: LogoutPage,
  },
  {
    path: ROUTES.REGISTER,
    title: 'routes.registration',
    icon: <DirectionsCar />,
    component: RegistrationPage,
  },
  {
    path: ROUTES.LOGIN,
    title: 'routes.login',
    icon: <DirectionsCar />,
    component: LoginPage,
  },
];

export const allRoutes = subPages
  .concat(pageRoutes)
  .concat(authRoutes)
  .concat([
    {
      path: '/',
      component: SubscriptionsPage,
    },
  ]);
