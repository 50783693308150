import juhlapesuLogo from '../assets/img/logo-juhlapesu.png';
import juhlapesuGooglePlayBadge from '../assets/img/google-play-badge-juhlapesu.png';
import juhlapesuAppStoreBadge from '../assets/img/appstore-badge-juhlapesu.svg';

import glimraLogo from '../assets/img/logo-glimra.png';
import glimraGooglePlayBadge from '../assets/img/google-play-badge-glimra.png';
import glimraAppStoreBadge from '../assets/img/appstore-badge-glimra.svg';

import landingPageBanner from '../assets/img/landing-page-banner.png';
import landingPageHero from '../assets/img/landing-page-image.jpg';

import signUpHeaderBanner from '../assets/img/sign-up-header-banner.png';

const getImages = () => {
  switch (process.env.REACT_APP_BRAND) {
    case 'juhlapesu':
      return {
        logo: juhlapesuLogo,
        badgeGooglePlay: juhlapesuGooglePlayBadge,
        badgeAppleAppStore: juhlapesuAppStoreBadge,
      };
    case 'glimra':
    default:
      return {
        logo: glimraLogo,
        badgeGooglePlay: glimraGooglePlayBadge,
        badgeAppleAppStore: glimraAppStoreBadge,
      };
  }
};

const commonImages = {
  landingPageBanner: landingPageBanner,
  landingPageHero: landingPageHero,
  signUpHeaderBanner: signUpHeaderBanner,
};

const IMAGES = { ...commonImages, ...getImages() };

export default IMAGES;
