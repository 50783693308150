import React, { useEffect, useState } from 'react';
import { Grid, withStyles, Container, Box, Typography } from '@material-ui/core';
import IMAGES from '../styles/images';
import { Link } from 'react-router-dom';
import { ROUTES } from '../navigation/routes';

const styles = (theme) => ({
  header: {
    top: 0,
    zIndex: theme.zIndex.drawer + 2,
    width: '100%',
    padding: '1rem 1rem 0.5rem',
    position: 'fixed',
    color: theme.palette.common.white,
    transition: theme.transitions.create(['background-color', 'box-shadow']),
  },

  elevateHeader: {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: theme.shadows[2],
  },

  pageHeading: {
    fontWeight: 600,
    zIndex: 10,
  },

  subtitle: {
    fontWeight: 600,
  },

  headerLogo: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  titlePlacer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  jumbotronContainer: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 150,
    },
    paddingTop: 125,
    paddingBottom: 100,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    backgroundImage: `url(${IMAGES.signUpHeaderBanner})`,
    backgroundPosition: 'center 45%',
    backgroundSize: 'cover',
  },
});

const Header = ({ classes, header, subtitle }) => {
  const [elevateHeader, setElevateHeader] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setElevateHeader(true);
    } else {
      setElevateHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`${classes.header} ${elevateHeader ? classes.elevateHeader : ''}`}>
        <Container maxWidth="xl">
          <Grid container alignItems="center">
            <Grid
              item
              xs={12}
              sm={4}
              className={classes.headerLogo}
              component={Link}
              to={ROUTES.LANDING}
            >
              <Box component="img" src={IMAGES.logo} alt="Logo" maxWidth={125} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="h4"
                component="h1"
                align="center"
                className={classes.pageHeading}
              >
                {header}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </header>
      <div className={classes.jumbotronContainer}>
        <Container maxWidth="sm">
          <Typography variant="h5" align="center" className={classes.subtitle}>
            {subtitle}
          </Typography>
        </Container>
      </div>
    </>
  );
};

export default withStyles(styles)(Header);
