import React, { useState } from 'react';
import { DialogActions, useTheme } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import GlimraStore from '../store/GlimraStore';
import SpacerVertical from './helpers/SpacerVertical';
import { useTranslation } from 'react-i18next';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import FabButton from './helpers/FabButton';

const PaymentMethodForm = ({ clientSecret, onClose, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const theme = useTheme();

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formError, setFormError] = useState(null);

  const submit = async () => {
    setButtonDisabled(true);

    if (!formError) {
      const cardElement = elements.getElement('card');
      const response = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (response && response.setupIntent) {
        const paymentMethod = response.setupIntent.payment_method;
        await GlimraStore.updatePaymentMethod(paymentMethod);
        onSuccess();
      } else {
        const error = response.error;
        if (error) {
          let exception = error.type + ': ' + error.code;
          if (error.decline_code) {
            exception += ', decline_code: ' + error.decline_code;
          }
          Sentry.captureException(new Error(exception));
        }
        window.alert(t('paymentMethodForm.somethingWentWrong'));
      }
    } else {
      window.alert(t('paymentMethodForm.somethingWentWrong'));
    }

    setButtonDisabled(false);
  };

  return (
    <>
      <CardElement
        onChange={(el) => {
          setFormError(el.error);
        }}
        options={{
          style: {
            base: {
              color: theme.palette.common.white,
              '::placeholder': {
                color: theme.palette.grey[300],
              },
            },
          },
        }}
      />
      <SpacerVertical />
      <DialogActions>
        <FabButton
          onClick={submit}
          disabled={buttonDisabled}
          variant="extended"
          fontSize="small"
          color="default"
        >
          {t('paymentMethodForm.buttonSave')}
        </FabButton>
      </DialogActions>
    </>
  );
};

export default PaymentMethodForm;
