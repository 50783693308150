// eslint-disable-next-line no-unused-vars
import React, { useState, useImperativeHandle, RefObject } from 'react';

import { makeStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.secondary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  info: {
    color: theme.palette.info.light,
  },
  warning: {
    color: theme.palette.grey[300],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.white,
  },
}));

const Snack = React.forwardRef((_, ref) => {
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: '',
  });

  useImperativeHandle(ref, () => ({
    updateSnack: (msgObj) => {
      setSnackState({ open: true, ...msgObj });
    },
  }));

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackState((prevState) => ({ ...prevState, open: false }));
  };

  const Icon = variantIcon[snackState.variant];
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={snackState.open}
      autoHideDuration={10000}
      onClose={handleClose}
    >
      <SnackbarContent
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon
              className={`
                ${classes[snackState.variant]} 
                ${classes.icon} 
                ${classes.iconVariant}
              `}
            />
            <b>{snackState.title}</b>, {snackState.message}
          </span>
        }
        action={
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
            <CloseIcon className={`${classes[snackState.variant]} ${classes.icon}`} />
          </IconButton>
        }
      />
    </Snackbar>
  );
});

/**
 * Functions available for the reference
 * @typedef {Object} SnackInterface
 * @property {function({variant: string, message: string, title: string}): void} updateSnack
 */
/**
 * A reference to the snack instance
 * @type {RefObject<SnackInterface>}
 */
export const snackRef = React.createRef();

const SnackWrapper = () => <Snack ref={snackRef} />;

export default SnackWrapper;
