const washPrograms = {
  0: {
    name: 'washPrograms.paus',
  },
  1: {
    name: 'washPrograms.degreasing',
  },
  2: {
    name: 'washPrograms.solvent',
  },
  3: {
    name: 'washPrograms.prewash',
  },
  4: {
    name: 'washPrograms.pressureWash',
  },
  5: {
    name: 'washPrograms.foam',
  },
  6: {
    name: 'washPrograms.rinse',
  },
  7: {
    name: 'washPrograms.wax',
  },
  8: {
    name: 'washPrograms.surfaceTreatment',
  },
  9: {
    name: 'washPrograms.insectRemoval',
  },
  10: {
    name: 'washPrograms.powerFoam',
  },
  11: {
    name: 'washPrograms.vacuumCleaner',
  },
};

export default washPrograms;
