import { path, pipe, values, head, when, until, not } from 'ramda';

export const extractErrorMessage = (error, fallbackMessage) => {
  return pipe(
    path(['response', 'data']),
    values,
    head,
    until((x) => typeof x !== 'object', pipe(values, head)),
    when(not, () => fallbackMessage)
  )(error);
};

export const extractErrorCode = (data) => {
  if (data === undefined) {
    return undefined;
  }

  if (Array.isArray(data)) {
    return data?.[0]?.code;
  }

  if (typeof data === 'object' && Object.keys(data).length > 0) {
    if ('code' in data) {
      return data?.code;
    }
    return extractErrorCode(data?.[Object.keys(data)[0]]);
  }

  return undefined;
};
