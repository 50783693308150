import React from 'react';
import { withStyles } from '@material-ui/core';
import MuiSelect from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

const Select = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: '1rem',
    borderRadius: '10px !important',
    paddingTop: 7,
  },
}))(MuiSelect);

const WhiteSelectField = ({ label, required, ...props }) => {
  const placeholder = !label && required ? props.placeholder + ' *' : props.placeholder;

  return (
    <>
      {label && (
        <Typography>
          {label}
          {required ? ' *' : ''}
        </Typography>
      )}
      <Select {...props} placeholder={placeholder} disableUnderline />
    </>
  );
};

export default WhiteSelectField;
