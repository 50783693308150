import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import ChooseSubscriptionStep1 from '../components/ChooseSubscriptionSteps/ChooseSubscriptionStep1';
import ChooseSubscriptionStep2 from '../components/ChooseSubscriptionSteps/ChooseSubscriptionStep2';
import ChooseSubscriptionStep3 from '../components/ChooseSubscriptionSteps/ChooseSubscriptionStep3';
import { useTranslation } from 'react-i18next';
import LoginContext from '../store/LoginContext';
import history from '../store/HistoryStore';
import { ROUTES } from '../navigation/routes';
import LoggedInRoot from '../components/helpers/LoggedInRoot';
import PageHeader from '../components/PageHeader';
import PaymentMethodBanner from '../components/PaymentMethodBanner';
import LoggedInContent from '../components/helpers/LoggedInContent';
import GlimraStore from '../store/GlimraStore';

const ChooseSubscriptionPage = () => {
  const { loggedIn } = useContext(LoginContext);
  const [step, setStep] = useState(1);
  const [chosenProgramId, setChosenProgramId] = useState();
  const [chosenAvailabilityOptionId, setChosenAvailabilityOptionId] = useState();
  const [chosenPaymentOptionId, setChosenPaymentOptionId] = useState();
  const [waxy, setWaxy] = useState(null); // extra special alternative
  const [canAddSubscription, setCanAddSubscription] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    GlimraStore.fetchPaymentMethod()
      .then(() => setCanAddSubscription(true))
      .catch(() => setCanAddSubscription(false));
  }, []);

  useEffect(() => {
    /* Scroll up on step change */
    if (!loggedIn) {
      history.push(ROUTES.LANDING);
    }

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [step, loggedIn]);

  const setWaxyProgram = () => {
    GlimraStore.fetchSubscriptionAlternatives(true).then((data) => {
      if (data.results) {
        const [program, binding, availability] = data.results;
        setWaxy({ program: [program], binding: [binding], availability: [availability] });
        setChosenProgramId(program.id);
        setChosenPaymentOptionId(binding.id);
        setChosenAvailabilityOptionId(availability.id);
      }
    });
  };

  const resetWizard = () => {
    setChosenProgramId();
    setChosenAvailabilityOptionId();
    setChosenPaymentOptionId();
    setStep(0);
  };

  const Step = () => {
    switch (step) {
      case 2:
        return (
          <ChooseSubscriptionStep2
            setStep={setStep}
            programId={chosenProgramId}
            availabiltyId={chosenAvailabilityOptionId}
            paymentIntervalId={chosenPaymentOptionId}
          />
        );
      case 3:
        return <ChooseSubscriptionStep3 resetWizard={resetWizard} />;
      case 1:
      default:
        return (
          <ChooseSubscriptionStep1
            chosenProgramId={chosenProgramId}
            chosenAvailabilityOptionId={chosenAvailabilityOptionId}
            chosenPaymentOptionId={chosenPaymentOptionId}
            canAddSubscription={canAddSubscription}
            setChosenProgramId={setChosenProgramId}
            setChosenAvailabilityOptionId={setChosenAvailabilityOptionId}
            setChosenPaymentOptionId={setChosenPaymentOptionId}
            setStep={setStep}
            waxy={waxy}
            setWaxy={setWaxy}
            setWaxyProgram={setWaxyProgram}
          />
        );
    }
  };

  return (
    <LoggedInRoot>
      <PageHeader
        header={t('chooseSubscriptionPage.header')}
        subtitle={t('chooseSubscriptionPage.subtitle')}
      />
      {!canAddSubscription && (
        <PaymentMethodBanner message={t('chooseSubscriptionPage.cannotAddSubscription')} />
      )}
      <LoggedInContent>
        <Box paddingTop="1rem">
          {step < 3 && (
            <Box paddingX="1rem">
              <Container maxWidth="sm" disableGutters>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
                  {t('chooseSubscriptionPage.step', { step: step, maxStep: 2 })}
                </Typography>
              </Container>
            </Box>
          )}

          <Step />
        </Box>
      </LoggedInContent>
    </LoggedInRoot>
  );
};

export default ChooseSubscriptionPage;
