import React, { useContext } from 'react';
import LoginContext from '../../store/LoginContext';
import { Redirect } from 'react-router-dom';

const LoggedInRoot = ({ children }) => {
  const loggedIn = useContext(LoginContext).loggedIn;

  return loggedIn ? <>{children}</> : <Redirect to={'/'} />;
};

export default LoggedInRoot;
