import React from 'react';
import { Button, Container, Grid } from '@material-ui/core';
import SpacerVertical from '../helpers/SpacerVertical';
import LoggedInCustomerForm from './LoggedInCustomerForm';
import { useTranslation } from 'react-i18next';

const ChooseSubscriptionStep2 = ({ setStep, programId, availabiltyId, paymentIntervalId }) => {
  const { t } = useTranslation();

  return (
    <>
      <Container maxWidth="sm">
        <Grid item>
          <Button onClick={() => setStep(1)}>{t('chooseSubscriptionStep2.buttonBack')}</Button>
          <SpacerVertical size="small" />
        </Grid>
        <LoggedInCustomerForm
          programId={programId}
          availabiltyId={availabiltyId}
          paymentIntervalId={paymentIntervalId}
          setStep={setStep}
        />
      </Container>
    </>
  );
};

export default ChooseSubscriptionStep2;
