import React, { useEffect, useState } from 'react';
import { AppBar, IconButton, Grid, Toolbar, withStyles, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import IMAGES from '../styles/images';
import GlimraStore from '../store/GlimraStore';
import history from '../store/HistoryStore';
import { ROUTES } from '../navigation/routes';

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    height: 32,
    width: 'auto',
    marginRight: 8,
    [theme.breakpoints.up('md')]: {
      height: 64,
    },
  },
  titlePlacer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  profilePlacer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  profileNamePlacer: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  profileIconPlacer: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const Header = ({ classes, toggleDrawer }) => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    GlimraStore.fetchUserProfile()
      .then((data) => {
        setUserData(data);
      })
      .catch(() => {});
  }, []);

  const toSettings = () => {
    history.push(ROUTES.SETTINGS);
  };

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar>
        <Grid container className={classes.toolbar}>
          <Box component={Grid} item xs={3} sm={3} display={{ md: 'none', lg: 'none', xl: 'none' }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={toggleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Grid container item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.titlePlacer}>
            <img src={IMAGES.logo} alt="glimra logo" className={classes.logo} />
          </Grid>
          <Grid
            container
            item
            xs={3}
            sm={3}
            md={6}
            lg={6}
            xl={6}
            spacing={2}
            className={classes.profilePlacer}
          >
            <Grid item className={classes.profileNamePlacer} onClick={toSettings}>
              <Box display={{ xs: 'none', md: 'block' }}>
                {userData.first_name + ' ' + userData.last_name}
              </Box>
            </Grid>
            <Grid item className={classes.profileIconPlacer} onClick={toSettings}>
              <PersonIcon />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
