import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import SpacerVertical from './SpacerVertical';

const Loader = () => {
  return (
    <>
      <SpacerVertical />
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
      <SpacerVertical />
    </>
  );
};

export default Loader;
