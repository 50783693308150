import * as Sentry from '@sentry/browser';
import { snackRef } from '../components/Snack';
import history from '../store/HistoryStore';
import i18next from 'i18next';
import { ROUTES } from '../navigation/routes';

const ErrorHelper = {
  handleApiError: (err) => {
    let extraContent = {};
    let message = err.request.response;
    let status = err.request.status;
    let url = err.request.responsURL;

    if (err.request) {
      url = err.request.responseURL;
      extraContent.request = err.request;
    }

    if (err.response) {
      extraContent.response = err.response;

      // Some endpoints return 404 when token is invalid
      if (status === 401 || status === 404) {
        snackRef.current.updateSnack({
          variant: 'warning',
          title: i18next.t('errorHelper.authorisationError.title'),
          message: i18next.t('errorHelper.authorisationError.message'),
        });

        history.push(ROUTES.LOGOUT);

        return new Promise((resolve) => {
          resolve({
            apiSuccess: false,
            msg: 'An error occur when communicating with the server',
          });
        });
      }
    }

    Sentry.configureScope((scope) => {
      scope.setTag({ url: url, status: status });
      scope.setExtras(extraContent);
    });

    message = 'API ERROR: ' + url + ' ' + status + ' ' + message;

    Sentry.captureMessage(message, {
      level: 'error',
    });

    snackRef.current.updateSnack({
      variant: 'error',
      title: i18next.t('errorHelper.communicationError.title'),
      message: i18next.t('errorHelper.communicationError.message'),
    });

    return new Promise((resolve) => {
      resolve({
        apiSuccess: false,
        msg: 'An error occur when communicating with the server',
      });
    });
  },

  handleBondaryError: (err, info) => {
    Sentry.setExtras(info);
    Sentry.captureException(err);
  },

  handleError: (err, component) => {
    Sentry.setExtras(component);
    Sentry.captureException(err);

    return new Promise((resolve) => {
      resolve({
        success: false,
        msg: 'An error occur when communicating with the server',
      });
    });
  },

  logWarning: (message, data) => {
    if (data) {
      Sentry.setExtras(data);
    }

    Sentry.captureMessage(message, {
      level: 'warning',
    });
  },
};

export default ErrorHelper;
