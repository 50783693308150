import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import MainMenu from './components/MainMenu';
import MainContent from './components/MainContent';
import Palette from './components/Palette';
import { CssBaseline, withStyles } from '@material-ui/core';
import { Router, Route, Switch } from 'react-router-dom';
import history from './store/HistoryStore';
import LoginContext from './store/LoginContext';
import AlternativesContext from './store/AlternativesContext';
import { Snack } from './components/Snack';
import CookieMessage from './components/CookieMessage';
import GlimraStore from './store/GlimraStore';
import AuthStore from './store/AuthStore';
import LandingPage from './pages/LandingPage';
import glimra_sv_SE from './translations/glimra_sv_SE';
import juhlapesu_sv_SE from './translations/juhlapesu_sv_SE';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Footer from './components/Footer';
import { initPixel } from './store/FBPixel';
import { AlternativeType } from './data/alternativeType';

import './styles/global.css';

const translation = process.env.REACT_APP_BRAND === 'juhlapesu' ? juhlapesu_sv_SE : glimra_sv_SE;

i18n.use(initReactI18next).init({
  resources: {
    sv_SE: {
      translation,
    },
  },
  lng: 'sv_SE',
  fallbackLng: 'sv_SE',

  interpolation: {
    escapeValue: false,
  },
});

const styles = () => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
  },
});

const App = ({ classes }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [navigationDrawerOpen, setNavigationDrawerOpen] = useState(false);
  const [alternatives, setAlternatives] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initPixel();
  }, []);

  useEffect(() => {
    if (!loggedIn) {
      setLoggedIn(AuthStore.getAuthStatus());
    }
  }, [loggedIn]);

  useEffect(() => {
    if (Object.keys(alternatives).length === 0) {
      GlimraStore.fetchSubscriptionAlternatives().then((data) => {
        const sortFunc = (a, b) => {
          if (a.price < b.price) {
            return -1;
          }
          if (b.price > a.price) {
            return 1;
          }
          return 0;
        };

        const alternatives = data.results.reduce(
          (prev, curr) => {
            if (curr.type === AlternativeType.AVAILABILITY) {
              return { ...prev, availability: [...prev.availability, curr].sort(sortFunc) };
            }
            if (curr.type === AlternativeType.PROGRAM) {
              return { ...prev, program: [...prev.program, curr].sort(sortFunc) };
            }
            return { ...prev, binding: [...prev.binding, curr].sort(sortFunc) };
          },
          { availability: [], binding: [], program: [] }
        );

        setAlternatives(alternatives);
        setLoading(false);
      });
    }
  }, [alternatives]);

  return (
    <Palette>
      <LoginContext.Provider value={{ loggedIn, setLoggedIn }}>
        <AlternativesContext.Provider value={{ alternatives, setAlternatives }}>
          <Router history={history}>
            <div className={classes.root}>
              <CssBaseline />
              <Switch>
                {!loggedIn && <Route exact path="/" component={LandingPage} />}
                <Route>
                  {loggedIn && (
                    <>
                      <Header toggleDrawer={() => setNavigationDrawerOpen(!navigationDrawerOpen)} />
                      <MainMenu
                        drawerOpen={navigationDrawerOpen}
                        closeDrawer={() => setNavigationDrawerOpen(false)}
                      />
                    </>
                  )}

                  <MainContent loggedIn={loggedIn} loading={loading} />
                </Route>
              </Switch>
              <Snack />
              <CookieMessage />
            </div>
          </Router>
          <Footer />
        </AlternativesContext.Provider>
      </LoginContext.Provider>
    </Palette>
  );
};

export default withStyles(styles)(App);
