import { withStyles } from '@material-ui/styles';
import MuiFab from '@material-ui/core/Fab';

const FabButton = withStyles((theme) => ({
  root: (props) =>
    props.color === 'default' && props.variant === 'extended'
      ? {
          color: theme.palette.defaultButton.contrastText,
          fontSize: props.size === 'small' || props.fontSize === 'small' ? '1rem' : '1.15rem',
          height: '2.25rem',
          fontWeight: '600',
          padding: props.size === 'small' ? '0 2rem !important' : '0 2.5rem !important',
          backgroundColor: theme.palette.defaultButton.main,
          '&:hover': {
            backgroundColor: theme.palette.defaultButton.dark,
            '@media (hover: none)': {
              backgroundColor: theme.palette.defaultButton.main,
            },
          },
        }
      : {},
}))(MuiFab);

export default FabButton;
