import React from 'react';
import { Grid, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import ValidateUserForm from './ValidateUserForm';
import NewCustomerForm from './NewCustomerForm';
import ExistingCustomerForm from './ExistingCustomerForm';
import SpacerVertical from '../helpers/SpacerVertical';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import GlimraStore from '../../store/GlimraStore';
import { snackRef } from '../../components/Snack';
import { extractErrorMessage } from '../../helpers/apiUtils';
import { useTranslation } from 'react-i18next';

const UnauthenticatedForm = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    userType: Yup.string().oneOf(['new', 'existing']),
    first_name: Yup.string().when('userType', {
      is: 'new',
      then: Yup.string().required(t('registrationStep1.firstNameRequired')),
    }),
    last_name: Yup.string().when('userType', {
      is: 'new',
      then: Yup.string().required(t('registrationStep1.lastNameRequired')),
    }),
    email: Yup.string().when('userType', {
      is: 'new',
      then: Yup.string()
        .required(t('registrationStep1.emailRequired'))
        .email(t('registrationStep1.emailFormat')),
    }),
    mobile: Yup.string().when('userType', {
      is: 'new',
      then: Yup.string().required(t('registrationStep1.mobileRequired')),
    }),
    marketing_ok: Yup.bool().when('userType', {
      is: 'new',
      then: Yup.bool(),
    }),
    invited_mobile: Yup.string().when('userType', {
      is: 'existing',
      then: Yup.string().required(t('registrationStep1.invitedMobileRequired')),
    }),
    accepts_terms: Yup.boolean().when('userType', {
      is: 'new',
      then: Yup.boolean().oneOf([true], t('registrationStep1.terms')),
    }),
  });

  const formik = useFormik({
    initialValues: {
      userType: 'new',
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      marketing_ok: false,
      accepts_terms: false,
      invited_mobile: '',
      invited_code: '',
    },
    onSubmit: (values) => {
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile: values.mobile,
        tos_version_accepted: values.accepts_terms ? 1 : 0,
        marketing_ok: false,
        create: true,
        desktop: true,
        country: process.env.REACT_APP_BRAND === 'juhlapesu' ? 'fi' : 'se',
      };

      GlimraStore.createUser(data)
        .then((responseData) => {
          formik.setFieldValue('invited_mobile', values.mobile);
        })
        .catch((error) => {
          snackRef.current.updateSnack({
            variant: 'error',
            title: t('registrationStep1.createUserError.title'),
            message: extractErrorMessage(error, t('registrationStep1.createUserError.message')),
          });
        });
    },
    validationSchema,
  });

  const renderSignInForm = () => {
    return (
      <>
        <RadioGroup
          value={formik.values.userType}
          onChange={(event) => formik.setFieldValue('userType', event.target.value)}
        >
          <Grid container item direction="row" xs={12}>
            <Grid item xs={6}>
              <FormControlLabel value={'new'} control={<Radio />} label="Ny kund" />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel value={'existing'} control={<Radio />} label="Befintlig kund" />
            </Grid>
          </Grid>
        </RadioGroup>
        <SpacerVertical size="small" />
        {formik.values.userType === 'existing' && <ExistingCustomerForm formik={formik} />}
        {formik.values.userType === 'new' && <NewCustomerForm formik={formik} />}
      </>
    );
  };

  return formik.values.invited_mobile ? <ValidateUserForm /> : renderSignInForm();
};

export default UnauthenticatedForm;
