import React from 'react';
import { withStyles, Toolbar } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import { allRoutes } from '../navigation/routes';
import Loader from './helpers/Loader';

const styles = (theme) => ({
  content: {
    flexGrow: 1,
    // paddingTop: theme.spacing(3),
  },
});

const MainContent = ({ classes, loading, loggedIn }) => {
  return (
    <main className={classes.content}>
      {loggedIn && <Toolbar />}
      {loading ? (
        <Loader />
      ) : (
        <Switch>
          {allRoutes.map((route, key) => {
            return <Route path={route.path} component={route.component} key={key} />;
          })}
        </Switch>
      )}
    </main>
  );
};

export default withStyles(styles)(MainContent);
