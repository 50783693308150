export const calculatePrice = (
  alternatives,
  chosenProgramId,
  chosenAvailabilityOptionId,
  chosenPaymentOptionId
) => {
  const programPrice =
    alternatives.program?.find((option) => option.id === chosenProgramId)?.price ?? 0;

  const availabilityPrice =
    alternatives.availability?.find((option) => option.id === chosenAvailabilityOptionId)?.price ??
    0;

  const bindingTimePrice =
    alternatives.binding?.find((option) => option.id === chosenPaymentOptionId)?.price ?? 0;

  return (programPrice + availabilityPrice + bindingTimePrice).toFixed(0);
};
