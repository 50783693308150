import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  paymentMethodBanner: {
    backgroundColor: '#ffa4a2',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
});

const PaymentMethodBanner = ({ message, classes }) => {
  return (
    <Grid container direction="column" className={classes.paymentMethodBanner}>
      <Grid item>
        <Typography variant="h6">{message}</Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PaymentMethodBanner);
