import React from 'react';
import { withStyles } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const TextField = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    paddingTop: 1,
    paddingLeft: '1rem',
  },
}))(MuiTextField);

const WhiteTextField = ({ label, required, ...props }) => {
  const placeholder = !label && required ? props.placeholder + ' *' : props.placeholder;

  return (
    <>
      {label && (
        <Typography>
          {label}
          {required ? ' *' : ''}
        </Typography>
      )}
      <TextField {...props} placeholder={placeholder} InputProps={{ disableUnderline: true }} />
    </>
  );
};

export default WhiteTextField;
