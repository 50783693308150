import React, { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, DialogActions } from '@material-ui/core';
import AuthStore from '../../store/AuthStore';
import LoginContext from '../../store/LoginContext';
import { snackRef } from '../../components/Snack';
import { extractErrorMessage } from '../../helpers/apiUtils';
import Button from '../helpers/Button';
import { useTranslation } from 'react-i18next';
import WhiteTextField from '../helpers/WhiteTextField';
import history from '../../store/HistoryStore';
import { ROUTES } from '../../navigation/routes';

const ValidateUserForm = ({ formik }) => {
  const { setLoggedIn } = useContext(LoginContext);
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ invited_code: '' }}
      validationSchema={Yup.object({
        invited_code: Yup.string().required(t('validateUserForm.invitedCodeRequired')),
      })}
      onSubmit={(values) => {
        AuthStore.login(values.invited_code)
          .then(() => {
            setLoggedIn(true);
            history.push(ROUTES.CHOOSE_SUBSCRIPTION);
          })
          .catch((error) => {
            snackRef.current.updateSnack({
              variant: 'error',
              title: t('existingCustomerForm.verificationCodeError.title'),
              message: extractErrorMessage(
                error,
                t('existingCustomerForm.verificationCodeError.message')
              ),
            });
          });
      }}
    >
      {({ errors, values, handleBlur, handleChange, handleSubmit, touched }) => (
        <Grid container direction="column" spacing={2}>
          <Grid item>{t('validateUserForm.writeCode')}</Grid>
          <Grid item>
            <WhiteTextField
              id="invited_code"
              label={t('validateUserForm.codeLabel')}
              type="tel"
              autoComplete="tel"
              required
              value={values.invited_code}
              error={touched.invited_code && !!errors.invited_code}
              helperText={touched.invited_code && errors.invited_code}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item>
            <DialogActions>
              <Button color="default" variant="contained" onClick={handleSubmit}>
                {t('validateUserForm.buttonSend')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default ValidateUserForm;
