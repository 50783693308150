export const SubscriptionStatus = {
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled',
  UNPAID: 'unpaid',
};

export const SubscriptionCancellationStatus = {
  CANCELLED: 'CANCELLED',
  QUEUED: 'QUEUED',
  NOT_CANCELLED: 'NOT_CANCELLED',
};
