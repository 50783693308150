import React, { useEffect, useState } from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import GlimraStore from '../store/GlimraStore';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from './helpers/Loader';
import Button from './helpers/Button';
import CreditCardDialog from '../components/CreditCardDialog';
import { useTranslation } from 'react-i18next';

const PaymentMethod = ({ onCompleteFlow }) => {
  const [loading, setLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [addCardDialogOpen, setAddCardDialogOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (loading) {
      GlimraStore.fetchPaymentMethod()
        .then((data) => {
          setPaymentMethod(data);
          if (onCompleteFlow) {
            onCompleteFlow();
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [loading, onCompleteFlow]);

  const removePaymentMethod = () => {
    GlimraStore.deletePaymentMethod()
      .then(() => setPaymentMethod({}))
      .catch(() => {
        // Error message handled by glimra store
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Grid container direction="row" spacing={2} justify="space-between" item xs={12} sm={6}>
          <Grid item xs={12}>
            <Typography variant="h5" noWrap>
              {t('paymentMethod.title')}
            </Typography>
            <Divider />
          </Grid>
          {paymentMethod.id ? (
            <>
              <Grid item>
                <Grid item>{t('paymentMethod.cardNumber')}</Grid>
                <Grid item>
                  {t('paymentMethod.cardNumberBlanked', { lastFour: paymentMethod.stripe_last4 })}
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<DeleteIcon />}
                  onClick={removePaymentMethod}
                >
                  {t('paymentMethod.buttonRemove')}
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <Button
                  onClick={() => setAddCardDialogOpen(true)}
                  variant="contained"
                  color="default"
                >
                  {t('paymentMethod.buttonAddCard')}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      )}
      <CreditCardDialog
        open={addCardDialogOpen}
        onClose={() => setAddCardDialogOpen(false)}
        onSuccess={() => {
          setLoading(true); // This will trigger a reload of payment options
          setAddCardDialogOpen(false);
        }}
      />
    </>
  );
};

export default PaymentMethod;
