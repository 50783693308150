import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import PaymentMethodForm from './PaymentMethodForm';
import GlimraStore from '../store/GlimraStore';
import Loader from './helpers/Loader';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ColoredDialog } from './helpers/ColoredDialog';

const useStyles = makeStyles({
  dialogContent: {
    minWidth: 300,
  },
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const CreditCardDialog = ({ open, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      GlimraStore.fetchStripeClientSecret()
        .then((secretResult) => {
          setClientSecret(secretResult.data?.stripe_client_secret);
          setLoading(false);
        })
        .catch(onClose);
    }
  }, [open, onClose]);

  return (
    <ColoredDialog
      disableEscapeKeyDown
      disableRestoreFocus
      disableEnforceFocus
      open={open}
      onClose={onClose}
      title={t('creditCardDialog.title')}
      titleVariant="h6"
      customClasses={{ dialogContent: classes.dialogContent }}
    >
      {loading ? (
        <Loader />
      ) : (
        <Elements stripe={stripePromise}>
          <PaymentMethodForm clientSecret={clientSecret} onClose={onClose} onSuccess={onSuccess} />
        </Elements>
      )}
    </ColoredDialog>
  );
};

export default CreditCardDialog;
