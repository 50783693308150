import axios from 'axios';
import Config from './Config';
import ErrorHelper from '../helpers/ErrorHelper';
import AuthStore from './AuthStore';
import { SubscriptionCancellationStatus } from '../data/subscriptionStatus';

class GlimraStore {
  constructor() {
    this.baseApiUrl = Config.baseApiUrl;
    this.country = process.env.REACT_APP_BRAND === 'juhlapesu' ? 'fi' : 'se';
    const key = localStorage.getItem('token');
    this.noAuthInstance = axios.create({
      baseURL: Config.baseApiUrl(),
    });
    this.instance = axios.create({
      baseURL: Config.baseApiUrl(),
      headers: {
        common: {
          Authorization: `Token ${key}`,
        },
      },
    });
  }

  createUser = (data) => {
    return this.noAuthInstance
      .post(`/account/auth/mobile/`, data)
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  fetchSubscriptionAlternatives = (isWaxyOnly) => {
    return this.noAuthInstance
      .get(`/api/v1/subscriptions/products/?is_waxy_only=${isWaxyOnly ? 'true' : 'false'}`, {
        params: {
          country: this.country,
        },
      })
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
      });
  };

  listSubscriptions = (page, pageSize, ordering) => {
    AuthStore.refreshToken();

    return this.instance
      .get(`/api/v1/subscriptions/`, {
        params: {
          page: page,
          page_size: pageSize,
          ordering,
        },
      })
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
      });
  };

  fetchAllUserSubscriptions = () => {
    AuthStore.refreshToken();

    return this.instance
      .get(`/api/v1/subscriptions/`, {})
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  createSubscription = (data) => {
    AuthStore.refreshToken();

    return this.instance
      .post(`/api/v1/subscriptions/`, data)
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  updateSubscription = (id, data) => {
    AuthStore.refreshToken();

    return this.instance
      .put(`/api/v1/subscriptions/subscription/${id}/`, data)
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  /* fetchExitOffer = (id) => {
    AuthStore.refreshToken();

    return this.instance
      .get(`/api/v1/subscription/${id}/churnoffer`)
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  }; */

  updateSubscriptionCancelled = (id, cancellationStatus) => {
    AuthStore.refreshToken();

    const getAction = () => {
      switch (cancellationStatus) {
        case SubscriptionCancellationStatus.CANCELLED:
          return 'reactivate';
        case SubscriptionCancellationStatus.QUEUED:
          return 'uncancel';
        case SubscriptionCancellationStatus.NOT_CANCELLED:
        default:
          return 'cancel';
      }
    };

    return this.instance
      .put(`/api/v1/subscriptions/subscription/${id}/${getAction()}/`, {})
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  /* renewSubscription = (id) => {
    AuthStore.refreshToken();

    return this.instance
      .post(`/api/v1/subscription/${id}/churnoffer`, {})
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  }; */

  fetchUserProfile = () => {
    AuthStore.refreshToken();

    return this.instance
      .get(`api/v1/users/me/`, {})
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  updateUserProfile = (data) => {
    AuthStore.refreshToken();

    return this.instance
      .put(`api/v1/users/me/`, data, {})
      .then(({ data }) => data)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  fetchPaymentMethod = () => {
    AuthStore.refreshToken();

    return this.instance
      .get(`api/v1/paymentmethod/mine/`, {})
      .then(({ data }) => data)
      .catch((error) => {
        // Returns 404 if no payment method exists and is a valid response, therefore do not use the ErrorHelper
        throw error;
      });
  };

  deletePaymentMethod = () => {
    AuthStore.refreshToken();

    return this.instance
      .delete(`api/v1/paymentmethod/mine/`, {})
      .then((response) => response)
      .catch((error) => {
        ErrorHelper.handleApiError(error);
        throw error;
      });
  };

  fetchStripeClientSecret = () => {
    AuthStore.refreshToken();

    return this.instance
      .post(`api/v1/paymentmethod/mine/setupintent`, {}, {})
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  };

  updatePaymentMethod = (paymentMethodId) => {
    AuthStore.refreshToken();

    return this.instance
      .post(`api/v1/paymentmethod/`, { stripe_payment_method_id: paymentMethodId }, {})
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  };

  listPayments = (page, pageSize, ordering) => {
    AuthStore.refreshToken();

    return this.instance
      .get(`/api/v1/payments/`, {
        params: {
          page: page,
          page_size: pageSize,
          gross_cost__gt: 0,
          company__isnull: true,
          ordering,
        },
      })
      .then((response) => response)
      .catch((err) => ErrorHelper.handleApiError(err));
  };

  fetchSubscription = (id) => {
    AuthStore.refreshToken();

    return this.instance
      .get(`/api/v1/subscriptions/subscription/${id}/`)
      .then(({ data }) => data)
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  fetchOneTimeCode = (id) => {
    AuthStore.refreshToken();

    return this.instance
      .post(`/api/v1/subscriptions/subscription/${id}/onetime/`, {})
      .then(({ data }) => data)
      .catch((err) => {
        ErrorHelper.handleApiError(err);
        throw err;
      });
  };

  fetchPayment = (uuid) => {
    AuthStore.refreshToken();

    return this.instance
      .get(`/api/v1/payments/${uuid}/`)
      .then(({ data }) => data)
      .catch((err) => ErrorHelper.handleApiError(err));
  };
}

const singleton = new GlimraStore();
export default singleton;
