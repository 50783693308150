import React, { useState } from 'react';
import { Box, Button, Snackbar, SnackbarContent, Grid } from '@material-ui/core';
import AuthStore from '../store/AuthStore';
import InfoIcon from '@material-ui/icons/Info';
import SpacerHorizontal from './helpers/SpacerHorizontal';
import SpacerVertical from './helpers/SpacerVertical';
import { useTranslation } from 'react-i18next';

const CookieMessage = () => {
  const [cookieSnackOpen, setCookieSnackOpen] = useState(!AuthStore.getCookieConsentStatus());
  const { t } = useTranslation();

  const acceptCookies = () => {
    AuthStore.acceptCookies();
    setCookieSnackOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={cookieSnackOpen}
    >
      <SnackbarContent
        message={
          <Grid container>
            <InfoIcon />
            <SpacerHorizontal size="small" />
            <Box>
              <Box fontWeight="fontWeightBold">{t('cookieMessage.title')}</Box>
              <Box>{t('cookieMessage.message')}</Box>
              <SpacerVertical size="small" />
            </Box>
          </Grid>
        }
        action={
          <Button variant="contained" onClick={acceptCookies}>
            {t('cookieMessage.buttonAccept')}
          </Button>
        }
      />
    </Snackbar>
  );
};

export default CookieMessage;
