import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FabButton from './FabButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: '1.5rem',
  },

  titleText: {
    fontWeight: '600',
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.white,
  },

  actions: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  actionsSpace: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    justifyContent: 'space-around',
  },
}));

export const ColoredDialog = ({
  open,
  onClose,
  title,
  titleAlign = 'left',
  children,
  closeButton = true,
  actions = [],
  titleVariant = 'h4',
  customClasses = {},
  ...dialogProps
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }} {...dialogProps}>
      <DialogTitle>
        <Typography align={titleAlign} variant={titleVariant} classes={{ root: classes.titleText }}>
          {title}
        </Typography>
        {closeButton && (
          <IconButton onClick={onClose} classes={{ root: classes.closeButton }}>
            <CloseIcon color="inherit" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={customClasses.dialogContent}>{children}</DialogContent>

      {actions.length > 0 && (
        <>
          <DialogActions className={actions.length === 2 ? classes.actionsSpace : classes.actions}>
            {actions.map((action) => (
              <FabButton
                key={action.text}
                onClick={action.onClick}
                variant="extended"
                color="default"
                fontSize="small"
              >
                {action.text}
              </FabButton>
            ))}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
