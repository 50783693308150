import React from 'react';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withWidth,
  Toolbar,
  isWidthUp,
  withStyles,
} from '@material-ui/core';
import { withRouter, useLocation } from 'react-router-dom';
import { pageRoutes, authRoutes } from '../navigation/routes';
import SpacerVertical from './helpers/SpacerVertical';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.grey.glimraMedium,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      borderRight: '0 !important',
      height: 'auto',
      position: 'sticky',
    },
  },
  drawerContainer: {
    overflow: 'auto',
  },
  link: {
    textDecoration: 'none',
  },
  activeLink: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  activeIcon: {
    color: theme.palette.common.white,
  },
});

const MainMenu = ({ drawerOpen, closeDrawer, history, width, classes }) => {
  const currentPath = useLocation().pathname;
  const logoutRoute = authRoutes[0];
  const isPermanent = isWidthUp('md', width);
  const { t } = useTranslation();

  const navigateFromDrawer = (path) => {
    history.push(path);
    closeDrawer();
  };

  return (
    <Drawer
      className={classes.drawer}
      variant={isPermanent ? 'permanent' : 'temporary'}
      open={drawerOpen}
      onClose={closeDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      {isPermanent && <SpacerVertical size="big" />}
      <div className={classes.drawerContainer}>
        <List>
          {pageRoutes.map((prop, key) => {
            const activeLink = currentPath === prop.path;
            return (
              <ListItem
                button
                key={key}
                onClick={() => navigateFromDrawer(prop.path)}
                className={activeLink ? classes.activeLink : ''}
              >
                <ListItemIcon className={activeLink ? classes.activeIcon : ''}>
                  {prop.icon}
                </ListItemIcon>
                <ListItemText>{t(prop.title)}</ListItemText>
              </ListItem>
            );
          })}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => navigateFromDrawer(logoutRoute.path)}>
            <ListItemIcon>{logoutRoute.icon}</ListItemIcon>
            <ListItemText>{t(logoutRoute.title)}</ListItemText>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default withStyles(styles)(withWidth()(withRouter(MainMenu)));
