import React, { useEffect, useState } from 'react';
import LoggedInRoot from '../components/helpers/LoggedInRoot';

import PageHeader from '../components/PageHeader';
import GlimraStore from '../store/GlimraStore';
import washPrograms from '../data/washPrograms';
import Loader from '../components/helpers/Loader';
import { formatAmount } from '../helpers/formatUtils';
import SpacerVertical from '../components/helpers/SpacerVertical';
import { Box, Divider, Grid } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import LoggedInContent from '../components/helpers/LoggedInContent';

const PaymentPage = ({
  match: {
    params: { uuid },
  },
}) => {
  const [payment, setPayment] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    GlimraStore.fetchPayment(uuid).then((data) => {
      setPayment(data);
      setLoading(false);
    });
  }, [uuid]);

  const renderWashPayment = () => {
    return (
      <>
        <Grid item container direction="row" justify="space-between">
          <Grid item>
            <Box fontWeight="fontWeightBold">{t('paymentPage.type')}</Box>
          </Grid>
          <Grid item>{t('paymentPage.diyWash')}</Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item>
          <Box fontWeight="fontWeightBold">{t('paymentPage.details')}</Box>
        </Grid>
        <Grid item container direction="column" alignItems="center">
          <Grid item>
            <SpacerVertical size="small" />
          </Grid>
          <Grid item xs={10} container direction="column" spacing={1}>
            {renderWashSteps()}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container direction="row" justify="space-between">
              <Grid item>
                <Box fontWeight="fontWeightBold">{t('paymentPage.totalTime')}</Box>
              </Grid>
              <Grid item>{payment.duration}</Grid>
            </Grid>
          </Grid>
          <Grid item>
            <SpacerVertical size="small" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </>
    );
  };

  const renderWashSteps = () => {
    const programs = payment.details_verbose?.programs;
    return (
      programs &&
      Object.keys(programs).map((step) => {
        return (
          <Grid item key={step}>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Box fontWeight="fontWeightBold">{t(washPrograms[step].name)}</Box>
              </Grid>
              <Grid item>{payment.details_verbose.programs[step].duration}</Grid>
            </Grid>
          </Grid>
        );
      })
    );
  };

  const renderSubscriptionPayment = () => {
    return (
      <>
        <Grid item container direction="row" justify="space-between">
          <Grid item>
            <Box fontWeight="fontWeightBold">{t('paymentPage.type')}</Box>
          </Grid>
          <Grid item>{t('paymentPage.subscription')}</Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item container direction="row" justify="space-between">
          <Grid item>
            <Box fontWeight="fontWeightBold">{t('paymentPage.vehicle')}</Box>
          </Grid>
          <Grid item>{payment.details_verbose.vehicle?.number}</Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </>
    );
  };

  return (
    <LoggedInRoot>
      <PageHeader header={t('paymentPage.header')} subtitle={t('paymentPage.subtitle')} />
      {loading ? (
        <Loader />
      ) : (
        <LoggedInContent>
          <Grid container xs={12} sm={8} md={6} direction="column" spacing={1}>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container direction="row" justify="space-between">
              <Grid item>
                <Box fontWeight="fontWeightBold">{t('paymentPage.date')}</Box>
              </Grid>
              <Grid item>{moment(payment.created).format('YY-MM-DD')}</Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {'vehicle' in payment.details_verbose
              ? renderSubscriptionPayment()
              : renderWashPayment()}

            {payment.digilet_delta < 0 && (
              <>
                <Grid item container direction="row" justify="space-between">
                  <Grid item>
                    <Box fontWeight="fontWeightBold">{t('paymentPage.originalAmount')}</Box>
                  </Grid>
                  <Grid item>{formatAmount(payment.gross_cost)}</Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item container direction="row" justify="space-between">
                  <Grid item>
                    <Box fontWeight="fontWeightBold">{t('paymentPage.usedCredits')}</Box>
                  </Grid>
                  <Grid item>{formatAmount(payment.digilet_delta)}</Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}

            <Grid item container direction="row" justify="space-between">
              <Grid item>
                <Box fontWeight="fontWeightBold">{t('paymentPage.amount')}</Box>
              </Grid>
              <Grid item>{formatAmount(payment.amount)}</Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </LoggedInContent>
      )}
    </LoggedInRoot>
  );
};

export default PaymentPage;
