import React, { useEffect, useState } from 'react';
import {
  CardContent,
  Grid,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Formik } from 'formik';
import GlimraStore from '../store/GlimraStore';
import Loader from '../components/helpers/Loader';
import * as Yup from 'yup';
import { snackRef } from '../components/Snack';
import { extractErrorMessage } from '../helpers/apiUtils';
import SpacerVertical from '../components/helpers/SpacerVertical';
import LoggedInRoot from '../components/helpers/LoggedInRoot';
import PageHeader from '../components/PageHeader';
import Button from '../components/helpers/Button';
import { useTranslation } from 'react-i18next';
import WhiteTextField from '../components/helpers/WhiteTextField';
import LoggedInContent from '../components/helpers/LoggedInContent';

const SettingsPage = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    GlimraStore.fetchUserProfile()
      .then((data) => {
        setUserData(data);
        setLoading(false);
      })
      .catch(() => {});
  }, []);

  return (
    <LoggedInRoot>
      <PageHeader header={t('settingsPage.header')} subtitle={t('settingsPage.subtitle')} />
      <LoggedInContent>
        <CardContent>
          {loading ? (
            <Loader />
          ) : (
            <Formik
              initialValues={{
                first_name: userData.first_name,
                last_name: userData.last_name,
                email: userData.email,
                email_receipts: userData.email_receipts,
              }}
              validationSchema={Yup.object().shape({
                first_name: Yup.string().required(t('settingsPage.firstNameRequired')),
                last_name: Yup.string().required(t('settingsPage.lastNameRequired')),
                email: Yup.string()
                  .required(t('settingsPage.emailRequired'))
                  .email(t('settingsPage.emailFormat')),
              })}
              onSubmit={(values) =>
                GlimraStore.updateUserProfile(values)
                  .then(() => {
                    snackRef.current.updateSnack({
                      variant: 'success',
                      title: t('settingsPage.updateUserProfileSuccess.title'),
                      message: t('settingsPage.updateUserProfileSuccess.message'),
                    });
                  })
                  .catch((error) => {
                    snackRef.current.updateSnack({
                      variant: 'error',
                      title: t('settingsPage.updateUserProfileError.title'),
                      message: extractErrorMessage(
                        error,
                        t('settingsPage.updateUserProfileError.message')
                      ),
                    });
                  })
              }
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
              }) => (
                <Grid container item xs={12} md={6} direction="column">
                  <Grid item>
                    <Typography variant="h5" noWrap>
                      {t('settingsPage.userSettings')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Divider />
                    <SpacerVertical size="small" />
                  </Grid>
                  <Grid item>
                    <WhiteTextField
                      required
                      id="first_name"
                      autoComplete="given-name"
                      label={t('settingsPage.firstNameLabel')}
                      value={values.first_name}
                      onChange={handleChange}
                      error={touched.first_name && !!errors.first_name}
                      helperText={touched.first_name && errors.first_name}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <WhiteTextField
                      required
                      id="last_name"
                      autoComplete="family-name"
                      label={t('settingsPage.lastNameLabel')}
                      value={values.last_name}
                      onChange={handleChange}
                      error={touched.last_name && !!errors.last_name}
                      helperText={touched.last_name && errors.last_name}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <WhiteTextField
                      required
                      id="email"
                      autoComplete="email"
                      label={t('settingsPage.emailLabel')}
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <WhiteTextField
                      disabled
                      id="mobile"
                      label={t('settingsPage.mobileLabel')}
                      value={userData.mobile}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="email_receipts"
                          color="primary"
                          onChange={handleChange}
                          value={values.email_receipts ? 'on' : 'off'}
                          checked={values.email_receipts}
                        />
                      }
                      margin="normal"
                      label={t('settingsPage.receiptsLabel')}
                      labelPlacement="start"
                      value={true}
                    />
                  </Grid>
                  <Grid item container direction="row" justify="flex-end">
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      color="default"
                      disabled={isSubmitting || !isValid}
                    >
                      {t('settingsPage.buttonUpdate')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Formik>
          )}
        </CardContent>
      </LoggedInContent>
    </LoggedInRoot>
  );
};

export default SettingsPage;
