import React, { useContext, useEffect } from 'react';
import { Box, Container } from '@material-ui/core';
import Header from '../components/SignUpHeader';
import { useTranslation } from 'react-i18next';
import LoginContext from '../store/LoginContext';
import history from '../store/HistoryStore';
import { ROUTES } from '../navigation/routes';
import ValidateUserForm from '../components/Registration/ValidateUserForm';

const LoginPage = () => {
  const { loggedIn } = useContext(LoginContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (loggedIn) {
      history.push(ROUTES.CHOOSE_SUBSCRIPTION);
    }
  }, [loggedIn]);

  return (
    <Box display="flex" flexDirection="column" bgcolor="grey.glimraLight">
      {!loggedIn && (
        <Header header={t('registrationPage.header')} subtitle={t('registrationPage.subtitle')} />
      )}
      <Box paddingTop="1rem">
        <Container maxWidth="sm">
          <ValidateUserForm />
        </Container>
      </Box>
    </Box>
  );
};

export default LoginPage;
