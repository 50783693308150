import styled from 'styled-components';
import theme from '../../styles/theme';

const getMarginFromSize = (size) => {
  switch (size) {
    case 'small':
      return theme.spacing(2);
    case 'big':
      return theme.spacing(8);
    default:
      return theme.spacing(4);
  }
};

const SpacerVertical = styled.div`
  padding-top: ${(props) => getMarginFromSize(props.size)}px;
`;

export default SpacerVertical;
