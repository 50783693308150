import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, DialogActions } from '@material-ui/core';
import GlimraStore from '../../store/GlimraStore';
import { snackRef } from '../../components/Snack';
import { extractErrorMessage } from '../../helpers/apiUtils';
import Button from '../helpers/Button';
import { useTranslation } from 'react-i18next';
import WhiteTextField from '../helpers/WhiteTextField';
import ValidateUserForm from './ValidateUserForm';

const ExistingCustomerForm = ({ formik }) => {
  const { t } = useTranslation();

  const renderSendValidationCodeForm = () => {
    return (
      <Formik
        initialValues={{ mobile: '' }}
        validationSchema={Yup.object({
          mobile: Yup.string().required(t('existingCustomerForm.mobileRequired')),
        })}
        onSubmit={(values) => {
          const data = {
            mobile: values.mobile,
            desktop: true,
          };

          GlimraStore.createUser(data)
            .then((responseData) => {
              formik.setFieldValue('invited_mobile', values.mobile);
            })
            .catch((error) => {
              snackRef.current.updateSnack({
                variant: 'error',
                title: t('existingCustomerForm.verificationCodeError.title'),
                message: extractErrorMessage(
                  error,
                  t('existingCustomerForm.verificationCodeError.message')
                ),
              });
            });
        }}
      >
        {({ errors, values, handleBlur, handleChange, handleSubmit, touched }) => (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <WhiteTextField
                id="mobile"
                label={t('existingCustomerForm.mobileLabel')}
                type="phone"
                required
                value={values.mobile}
                error={touched.mobile && !!errors.mobile}
                helperText={touched.mobile && errors.mobile}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item>
              <DialogActions>
                <Button color="default" variant="contained" onClick={handleSubmit}>
                  {t('existingCustomerForm.buttonSend')}
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        )}
      </Formik>
    );
  };

  return formik.values.invited_mobile ? <ValidateUserForm /> : renderSendValidationCodeForm();
};

export default ExistingCustomerForm;
