/**
 * Get the currency depending on the country specified in backend
 * @param {string} country - The country, returned from backend
 * @returns The currency used in the country
 */
const getCurrency = (country) => {
  switch (country) {
    case 'fi':
      return 'EUR';
    case 'se':
    default:
      return 'SEK';
  }
};

export default getCurrency;
